<template>
  <v-card elevation="1" tile class="pa-5">
    <v-row>
      <v-col cols="12" md="6" class="subtitle-1 font-weight-bold mb-5">
        INFORME RECLAMOS INGRESADOS POR BANCO
      </v-col>
      <v-col cols="12" md="6" class="text-right">
        <v-radio-group
          v-model="groupBySearch"
          row
          label="Agrupación por fecha de:"
          class="mt-0 mb-0"
        >
          <v-radio label="creación" :value="false"></v-radio>
          <v-radio label="ultima modificación" :value="true"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-card-text>
      <v-form v-model="form.isValid" @submit.prevent="search">
        <v-row>
          <v-col cols="12" md="2"
            ><v-select
              label="Fecha desde"
              outlined
              dense
              v-model="dateSince"
              :items="dateSelect"
            >
            </v-select
          ></v-col>
          <v-col cols="12" md="2">
            <v-select
              label="Fecha hasta"
              outlined
              dense
              v-model="dateUntil"
              :items="dateSelect"
            >
            </v-select>
          </v-col>
          <v-col cols="12" md="2">
            <v-btn
              class="mx-2"
              fab
              small
              dark
              color="blue lighten-3"
              @click="clean"
              :loading="processing"
            >
              <v-icon>
                mdi-eraser
              </v-icon>
            </v-btn>
            <v-btn
              type="submit"
              class="mx-2"
              fab
              small
              color="primary"
              :loading="processing"
              :disabled="!form.isValid || processing"
            >
              <v-icon dark>
                mdi-magnify
              </v-icon>
            </v-btn>
            <ImportExcel
              :disabled="dataComputed.length == 0"
              :loading="processing"
              :data="dataComputedExcel"
              :json_fields="headersComputedExcel"
              name="Informe-Reclamos-Ingresados-Por-Banco"
            />
          </v-col>
        </v-row>
      </v-form>

      <v-row v-if="dataComputed.length > 0">
        <v-col cols="12" sm="12" md="9" xl="7">
          <v-data-table
            :headers="headersComputed"
            :items="dataComputed"
            :items-per-page="1000"
            hide-default-footer
            dense
            item-key="bankCode"
            :single-expand="true"
            :expanded.sync="expanded"
          >
            <template v-slot:[`item.bankCode`]="{ item }">
              {{ getBankName(item.bankCode) }}
            </template>

            <template v-slot:[`item.v2025`]="{ item }">
              {{ formatNumber(item.v2025) }}
            </template>
            <template v-slot:[`item.t2025`]="{ item }">
              {{ formatNumber(item.t2025) }}
            </template>

            <template v-slot:[`item.v2024`]="{ item }">
              {{ formatNumber(item.v2024) }}
            </template>
            <template v-slot:[`item.t2024`]="{ item }">
              {{ formatNumber(item.t2024) }}
            </template>

            <template v-slot:[`item.v2023`]="{ item }">
              {{ formatNumber(item.v2023) }}
            </template>
            <template v-slot:[`item.t2023`]="{ item }">
              {{ formatNumber(item.t2023) }}
            </template>

            <template v-slot:[`item.v2022`]="{ item }">
              {{ formatNumber(item.v2022) }}
            </template>
            <template v-slot:[`item.t2022`]="{ item }">
              {{ formatNumber(item.t2022) }}
            </template>

            <template v-slot:[`item.v2021`]="{ item }">
              {{ formatNumber(item.v2021) }}
            </template>
            <template v-slot:[`item.t2021`]="{ item }">
              {{ formatNumber(item.t2021) }}
            </template>

            <template v-slot:[`item.v2020`]="{ item }">
              {{ formatNumber(item.v2020) }}
            </template>
            <template v-slot:[`item.t2020`]="{ item }">
              {{ formatNumber(item.t2020) }}
            </template>

            <template v-slot:[`item.v2019`]="{ item }">
              {{ formatNumber(item.v2019) }}
            </template>
            <template v-slot:[`item.t2019`]="{ item }">
              {{ formatNumber(item.t2019) }}
            </template>

            <template slot="body.append">
              <tr>
                <td v-for="header in headersComputed" :key="header.value">
                  {{ sumarizeNew(header) }}
                </td>
              </tr>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">{{ item }}</td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row v-if="dataComputed.length > 0">
        <v-col cols="12" md="12">
          <canvas height="100" id="claims-graph"></canvas>
        </v-col>
      </v-row>
    </v-card-text>

    <Processing :viewProcessing="processing" title="Cargando..." />
    <Alert
      :dialog="alert.show"
      :msj="alert.message"
      @close="alert.show = false"
    />
  </v-card>
</template>

<script>
import reportService from "@/services/report";
import baseViewMixin from "@/mixins/baseView";
import parameters from "@/mixins/parameters";
import report from "@/mixins/report";

import Alert from "@/components/Alert";
import Processing from "@/components/Processing";
import ImportExcel from "@/components/ImportExcel";

export default {
  mixins: [baseViewMixin, parameters, report],
  components: {
    Processing,
    ImportExcel,
    Alert,
  },
  methods: {
    search() {
      this.result = [];

      if (this.dateSince != "" && this.dateUntil != "") {
        if (this.dateSince > this.dateUntil) {
          this.alert.message =
            "La fecha desde no puede ser mayor a la fecha hasta";
          this.alert.show = true;
          return;
        }
      }

      this.processing = true;

      reportService
        .getClaimstatisticsAll(this.groupBySearch)
        .then((result) => {
          this.result = result.data;

          this.processing = false;

          if (this.result.length == 0) {
            this.alert.message = "No hay registros";
            this.alert.show = true;
            return;
          }

          if (this.result.length > 0) {
            setTimeout(
              () =>
                this.createChart("claims-graph", "bar", this.dataComputedGraph),
              1500
            );
          }
        })
        .catch((err) => {
          this.alert.message = err;
          this.alert.show = true;
          this.processing = false;
        });
    },
    sumarize(pos) {
      let result = 0;
      for (let index = 0; index < this.dataComputed.length; index++) {
        const element = this.dataComputed[index];
        const convert = Object.keys(element).map((key) => [key, element[key]]);
        let value = 0;
        try {
          value = convert[pos][1];
        } catch {
          value = 0;
        }

        if (value != undefined || value != null) result = result + value;
      }
      return this.$n(result);
    },
    sumarizeNew(header) {
      if (header.value == "bankCode") return "Total";
      if (header.text == "") return "";

      let result = 0;
      this.dataComputed.forEach((d) => {
        let value = 0;
        try {
          value = d[header.value];
        } catch {
          value = 0;
        }
        if (!isNaN(value)) result = result + value;
      });

      return this.$n(result);
    },
    validateStateFinal(state) {
      const convert = Object.keys(state).map((key) => [key, state[key]]);
      if (convert.length < 6) {
        this.headersDefault.forEach((h) => {
          if (state[h.value] == undefined || state[h.value] == null)
            state[h.value] = 0;
        });
      }
    },
  },
  computed: {
    headersComputed() {
      return this.headersDefault;
    },
    headersDefault() {
      let resultFinal = [];

      resultFinal.push({
        text: "Bancos",
        value: "bankCode",
        sortable: false,
      });
      const yearNow = new Date().getFullYear().toString();

      resultFinal.push({
        text: `${yearNow}`,
        value: `v${yearNow}`,
        sortable: true,
      });
      resultFinal.push({
        text: `${yearNow - 1}`,
        value: `v${yearNow - 1}`,
        sortable: true,
      });
      resultFinal.push({
        text: `${yearNow - 2}`,
        value: `v${yearNow - 2}`,
        sortable: true,
      });
      resultFinal.push({
        text: `${yearNow - 3}`,
        value: `v${yearNow - 3}`,
        sortable: true,
      });

      resultFinal.push({
        text: `Total ${yearNow - 1}`,
        value: `t${yearNow - 1}`,
        sortable: true,
      });
      resultFinal.push({
        text: `Total ${yearNow - 2}`,
        value: `t${yearNow - 2}`,
        sortable: true,
      });
      resultFinal.push({
        text: `Total ${yearNow - 3}`,
        value: `t${yearNow - 3}`,
        sortable: true,
      });

      //resultFinal.push({ text: "", value: "data-table-expand" });

      return resultFinal;
    },
    headersComputedExcel() {
      let result = {};

      if (this.headersComputed.length > 0) {
        for (let index = 0; index < this.headersComputed.length; index++) {
          if (this.headersComputed[index].text != "") {
            result[
              `.${this.headersComputed[index].text}`
            ] = this.headersComputed[index].value;
          }
        }
      }

      return result;
    },
    dataComputed() {
      const yearNow = new Date().getFullYear().toString();

      const result = this.groupBy(this.result, "bankCode"); //agrupa por una atrinuto, como resultado agrupa y el otro valor son todos los registros
      const convert = Object.keys(result).map((key) => [key, result[key]]); //ya que no se sabe los atributos, se convierte a una sub arreglo ************ con este truco se puede trabajar datos dinamicamente

      let resultFinal = [];
      for (let index = 0; index < convert.length; index++) {
        //recorrer el resultado por estado y volver agrupar por años
        let bank = convert[index];

        const resultGroupState = this.groupBy(bank[1], "year");
        const convertResultGroupState = Object.keys(
          resultGroupState
        ).map((key) => [key, resultGroupState[key]]);

        let stateFinal = {
          bankCode: bank[0],
        };

        //filter
        const convertResultGroupStateReverse = convertResultGroupState.reverse();
        for (let a = 0; a < convertResultGroupStateReverse.length; a++) {
          const sumOf = convertResultGroupStateReverse[a][1].reduce(
            (sum, detail) => {
              const validationFilter = this.filter(detail);
              if (validationFilter) return sum + detail.value;

              return sum;
            },
            0
          );

          stateFinal[`v${convertResultGroupStateReverse[a][0]}`] = sumOf;
        }

        //total
        for (let a = 0; a < convertResultGroupStateReverse.length; a++) {
          const sumOf = convertResultGroupStateReverse[a][1].reduce(
            (sum, detail) => {
              return sum + detail.value;
            },
            0
          );

          if (yearNow != convertResultGroupStateReverse[a][0])
            stateFinal[`t${convertResultGroupStateReverse[a][0]}`] = sumOf;
        }
        this.validateStateFinal(stateFinal);
        resultFinal.push(stateFinal);
      }

      return resultFinal;
    },
    dataComputedExcel() {
      let data = JSON.parse(JSON.stringify(this.dataComputed));
      for (let i = 0; i < data.length; i++) {
        data[i].bankCode = this.getBankName(data[i].bankCode);
      }

      return data;
    },
    dataComputedGraph() {
      const yearNow = new Date().getFullYear().toString();
      let etiquetas = [];
      let datasets = [];

      let años = [];

      años = [
        [
          yearNow,
          `v${yearNow}`,
          "rgba(54, 162, 235, 0.2)",
          "rgba(54, 162, 235, 1)",
        ],
        [
          yearNow - 1,
          `v${yearNow - 1}`,
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 159, 64, 1)",
        ],
        [
          yearNow - 2,
          `v${yearNow - 2}`,
          "rgba(0, 152, 70, 0.2)",
          "rgba(0, 152, 70, 1)",
        ],
        [
          yearNow - 3,
          `v${yearNow - 3}`,
          "rgba(245, 46, 10, 0.2)",
          "rgba(245, 46, 10, 1)",
        ],
      ];

      for (let a = 0; a < años.length; a++) {
        let dataAño = [];
        for (let index = 0; index < this.dataComputed.length; index++) {
          const element = this.dataComputed[index];

          const findEtiqueta = etiquetas.find(
            (e) => e == this.getBankName(element.bankCode)
          );
          if (findEtiqueta == null)
            etiquetas.push(this.getBankName(element.bankCode));

          const convert = Object.keys(element).map((key) => [
            key,
            element[key],
          ]);
          const position = años[a][1];
          try {
            for (let c = 0; c < convert.length; c++) {
              if (convert[c][0] == position) {
                dataAño.push(convert[c][1]);
              }
            }
          } catch (error) {
            //console.log(error);
          }
        }

        const datasetAño = {
          label: años[a][0],
          data: dataAño, // La data es un arreglo que debe tener la misma cantidad de valores que la cantidad de etiquetas
          backgroundColor: años[a][2], // Color de fondo
          borderColor: años[a][3], // Color del borde
          borderWidth: 1, // Ancho del borde
        };

        datasets.push(datasetAño);
      }

      let result = {
        labels: etiquetas,
        datasets: datasets,
      };

      return result;
    },
  },
};
</script>

<style lang="scss">
.v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none;
}
</style>
