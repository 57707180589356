import Chart from "chart.js";

export default {
  data() {
    return {
      groupBySearch: true,
      processing: false,
      dateSince: "",
      dateUntil: "",
      bank: "",
      banks: [],
      result: [],
      expanded: [],
      expandedBank: [],
      dateSelect: [
        {
          value: 1,
          text: "Enero",
        },
        {
          value: 2,
          text: "Febrero",
        },
        {
          value: 3,
          text: "Marzo",
        },
        {
          value: 4,
          text: "Abril",
        },
        {
          value: 5,
          text: "Mayo",
        },
        {
          value: 6,
          text: "Junio",
        },
        {
          value: 7,
          text: "Julio",
        },
        {
          value: 8,
          text: "Agosto",
        },
        {
          value: 9,
          text: "Septiembre",
        },
        {
          value: 10,
          text: "Octubre",
        },
        {
          value: 11,
          text: "Noviembre",
        },
        {
          value: 12,
          text: "Diciembre",
        },
      ],
      allState: [],
    };
  },
  methods: {
    dummy() {
      const banks = ["BCH", "BES", "BIT", "BSC"];
      const states = [
        "EXPIRADO",
        "RECHAZADO-FONDO",
        "TRAMITACION-SIMPLIFICADA-ACEPTAR-PROPUESTA",
        "RECHAZADO-FONDO-DEFENSOR",
        "AVENIMIENTO-ACEPTADO",
        "FALLO-A-FAVOR-CLIENTE",
        "FALLO-FAVOR-BANCO",
      ];

      for (let y = 2019; y <= 2021; y++) {
        for (let m = 1; m <= 12; m++) {
          for (let b = 0; b < banks.length; b++) {
            for (let s = 0; s < states.length; s++) {
              this.result.push({
                year: y,
                month: m,
                stateCode: states[s],
                bankCode: banks[b],
                value: this.randomNumber(),
              });
            }
          }
        }
      }
    },
    createChart(id, type, data) {
      if (data == null) return;
      const ctx = document.getElementById(id);
      new Chart(ctx, {
        type: type,
        data: data,
        options: { responsive: true },
      });
    },
    groupBy(array, key) {
      return Array.from(array).reduce((result, currentValue) => {
        // If an array already present for key, push it to the array. Else create an array and push the object
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue
        );
        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return result;
      }, {}); // empty object is the initial value for result object
    },
    formatNumber(value) {
      if (value == undefined || value == null || value == "") return 0;

      return this.$n(value);
    },
    randomNumber() {
      return Math.floor(Math.random() * 101);
    },
    clean() {
      this.dateSince = "";
      this.dateUntil = "";
      this.bank = "";
    },
    evaluarFechaDesde(value) {
      if (value == "") {
        return true;
      }

      if (this.dateUntil != "") if (value > this.dateUntil) return false;

      return true;
    },
    evaluarFechaHasta(value) {
      if (value != "") {
        return true;
      }
      if (this.dateSince != "") if (this.dateSince > value) return false;

      return true;
    },
    filter(detail) {
      if (this.dateSince == "" && this.dateUntil == "" && this.bank == "")
        return true;

      if (this.dateSince == "" && this.dateUntil == "" && this.bank != "") {
        if (detail.bankCode == this.bank) return true;
      }

      const monthParse = parseInt(detail.month);

      if (this.dateSince != "" && this.dateUntil == "" && this.bank == "") {
        if (monthParse >= this.dateSince) return true;
      }

      if (this.dateSince != "" && this.dateUntil == "" && this.bank != "") {
        if (monthParse >= this.dateSince && detail.bankCode == this.bank)
          return true;
      }

      if (this.dateSince == "" && this.dateUntil != "" && this.bank == "") {
        if (monthParse <= this.dateUntil) return true;
      }

      if (this.dateSince == "" && this.dateUntil != "" && this.bank != "") {
        if (monthParse <= this.dateUntil && detail.bankCode == this.bank)
          return true;
      }

      if (this.dateSince != "" && this.dateUntil != "" && this.bank == "") {
        if (monthParse >= this.dateSince && monthParse <= this.dateUntil)
          return true;
      }

      if (this.dateSince != "" && this.dateUntil != "" && this.bank != "") {
        if (
          monthParse >= this.dateSince &&
          monthParse <= this.dateUntil &&
          detail.bankCode == this.bank
        )
          return true;
      }

      return false;
    },
  },
  computed: {},
  watch: {
    expanded() {
      this.expandedBank = [];
    },
    dateSince() {
      this.result = [];
      this.expanded = [];
      this.expandedBank = [];
    },
    dateUntil() {
      this.result = [];
      this.expanded = [];
      this.expandedBank = [];
    },
    bank() {
      this.result = [];
      this.expanded = [];
      this.expandedBank = [];
    },
  },
  beforeMount() {
    const parametersBank = this.$Parameters.all.find(
      (parameter) => parameter.type == "MigrationBanks"
    );
    this.banks = parametersBank.values;

    const statuses = this.$Parameters.all.find(
      (parameter) => parameter.type == "WfStatuses"
    ).values;

    let result = [];
    for (let i = 0; i < statuses.length; i++) {
      result.push(statuses[i].code);
    }
    this.allState = result;
  },
};
